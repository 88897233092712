import React, { Component } from 'react'
import { graphql } from 'gatsby'
//import he from 'he'
import moment from 'moment'

import SearchForm from '../components/search'

import Seo from '../components/seo'
import PropertyCard from '../components/cards/property'
import Thinking from '../components/thinking'

class BuyPage extends Component {

  state = {
    display: this.props.data.properties || []
  }

  render() {

    let { display } = this.state
    const { properties } = this.props.data

    return (
      <>
        <Seo title="Buy - Mara+Co" bodyClass='sold' description="At MARA & CO our team are passionate about being truly there with you and for you throughout the selling process. With over a decade of combined experience in property sales, our team strives to deliver a service that is seamless and stress free." />
        <section className="properties">
          <SearchForm list={properties || []} set={list => this.setState({ display: list })} type='buy' />
          <div className="properties__inner">
            { display && display.edges.map((el, i) => {
              if ( i === 3 ) {
                return (
                  <>
                    <Thinking
                      title={'selling'}
                      short={'At Mara + Co our team are passionate about being truly there with you and for you throughout the selling process. With over a decade of combined experience in property sales, our team strives to deliver a service that is seamless and stress free.'}
                      long={'For us selling your home is not about a transaction it’s about building relationships and helping you through every step of the way; before your property comes onto the market, during the sales process and advising, helping with the next move after the sale.'}
                    key={i} />
                    <PropertyCard postType='property' sold={true} {...el.node} key={i} />
                  </>
                )
              } else {
                return (
                  <PropertyCard postType='property' sold={true} {...el.node} key={i} />
                )
              }
            })}
          </div>
        </section>
      </>
    )
  }
}

export default BuyPage

export const buyQuery = graphql`
  query {
    site {
      buildTime
    }
    properties: allWpProperty(
      sort: {
        fields: propertyListing___onMarketDate,
        order: DESC
      },
      filter:{
        propertyListing :{ status:{in:["Settled", "Unconditional"]}}
      },
      limit: 100
    )
    {
      edges {
        node {
          databaseId
          id
          slug
          title
          propertyAddress {
            hideAddress
            streetAddress
            suburb
            state
          }
          propertyData {
            bathrooms
            bedrooms
            type
            carPorts
            carSpaces
            garages
            totalParking
          }
          propertyImages {
            url
            small
            large
          }
          propertyListing {
            searchPrice
            displayPrice
            marketingStatus
          }
        }
      }
    }
  }
`
